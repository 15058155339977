<template>
  <el-dialog
    v-model="isShow"
    title="巡检详情"
    width="500px"
    :before-close="handleClose"
  >
  <div  >
    <div>
      <el-descriptions column="1">
        <el-descriptions-item label="巡检结果:">{{
          selectDictLabel(dict.type.inspection_record_result, detailForm.result)
        }}</el-descriptions-item>
        <el-descriptions-item label="巡检编号:">{{detailForm.inspectionNo }}</el-descriptions-item>
        <el-descriptions-item label="设备编号:">{{detailForm.deviceNo }}</el-descriptions-item>
        <el-descriptions-item label="设备名称:">{{
          detailForm.deviceLocation?.deviceName
        }}</el-descriptions-item>
        <el-descriptions-item label="项目名称:">
          {{ detailForm.deviceLocation?.project.name }}
        </el-descriptions-item>
        <el-descriptions-item label="项目类型:">{{
          detailForm.deviceLocation?.project.projectType.name
        }}</el-descriptions-item>
        <el-descriptions-item label="巡检人:">{{
          detailForm.userName
        }}</el-descriptions-item>
        <el-descriptions-item label="巡检时间:">{{
          detailForm.inspectionTime
        }}</el-descriptions-item>
        <el-descriptions-item label="创建时间:">{{
          detailForm.createTime
        }}</el-descriptions-item>
      </el-descriptions>
    </div>
    <el-divider style="width:90%;margin:auto;margin-bottom: 5px;" />
    <div>
      <el-descriptions column="1">
        <el-descriptions-item label="外观是否完整:">{{ detailForm.completeAppearance ? "是" : "否;" }}{{ detailForm.appearanceDescription }}
          <div>
            <el-image
              v-for="url in appearanceList"
              style="width: 50px; height: 50px"
              :preview-src-list="[url]"
              :src="url"
              fit="contain"
            />
          </div>
        </el-descriptions-item>
        <el-descriptions-item label="立柱安装是否牢固:">{{ detailForm.columnInstallation ? "是" : "否;" }}{{ detailForm.columnInstallationDescription }}
          <div>
            <el-image
              v-for="url in columnList"
              style="width: 50px; height: 50px"
              :preview-src-list="[url]"
              :src="url"
              fit="contain"
            />
          </div>
        </el-descriptions-item>
        <el-descriptions-item label="插座安装是否牢固:">{{ detailForm.socketInstallation ? "是" : "否;" }}{{ detailForm.socketInstallationDescription }}
          <div>
            <el-image
              v-for="url in socketlist"
              style="width: 50px; height: 50px"
              :preview-src-list="[url]"
              :src="url"
              fit="contain"
            />
          </div>
        </el-descriptions-item>
        <el-descriptions-item label="排管走线是否合理:">{{ detailForm.pipelineRouting ? "是" : "否;" }}{{ detailForm.pipelineRoutingDescription }}
          <div>
            <el-image
              v-for="url in pipelineRoutinglist"
              style="width: 50px; height: 50px"
              :preview-src-list="[url]"
              :src="url"
              fit="contain"
            /></div
        ></el-descriptions-item>
        <el-descriptions-item label="存在通电问题的端口">{{detailForm.issuePorts ? "是" : "无" }}</el-descriptions-item>
        <el-descriptions-item label="投币充电是否正常:">{{ detailForm.abnormalCoinCharging ? "是" : "否;" }}{{ detailForm.abnormalCoinChargingDescription }}
          <div>
            <el-image
              v-for="url in abnormalCoinCharginglist"
              style="width: 50px; height: 50px"
              :preview-src-list="[url]"
              :src="url"
              fit="contain"
            /></div
        ></el-descriptions-item>
        <el-descriptions-item label="按键是否正常:">{{ detailForm.buttonNormal ? "是" : "否;" }}{{ detailForm.buttonNormalDescription }}
          <div>
            <el-image
              v-for="url in pipelineRoutinglist"
              style="width: 50px; height: 50px"
              :preview-src-list="[url]"
              :src="url"
              fit="contain"
            /></div
        ></el-descriptions-item>
        <el-descriptions-item label="无其他故障现象:">{{ detailForm.otherProblem ? "是" : "否;" }}{{ detailForm.otherProblemDescription }}
          <div>
            <el-image
              v-for="url in otherProblemlist"
              style="width: 50px; height: 50px"
              :preview-src-list="[url]"
              :src="url"
              fit="contain"
            /></div
        ></el-descriptions-item>
        <el-descriptions-item label="产生工单:">{{detailForm.createWorkOrder ? "是" : "否" }}</el-descriptions-item>
        <el-descriptions-item label="工单负责人:">{{detailForm.deviceLocation?.project.responsibleUserId}}</el-descriptions-item>
      </el-descriptions>
    </div>
  </div>
    <template #footer>
      <div>
        <span>
          <el-button @click="handleClose">关闭</el-button>
        </span>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import { getInspectionRecord } from "@/api/inspection_record";
import { isResOK } from "@/api/response";
import { selectDictLabel } from "@/utils/ruoyi";
let baseURL = process.env.VUE_APP_BASE_API;
export default {
  name: "NewProject",
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
  },
  dicts: ["inspection_record_result"],
  emits: ["update:visible", "completed"],
  data() {
    return {
      detailForm: {},
      appearanceList:[],//外观图片
      columnList:[],//立柱图片
      socketlist: [], //插座图片
      pipelineRoutinglist: [], //排管走线图片
      abnormalCoinCharginglist: [], //投币图片
      buttonNormallist: [], //按键图片
      otherProblemlist: [], //其他故障图片
    };
  },
  computed: {
    isShow: {
      get() {
        return this.visible;
      },

      set(val) {
        this.$emit("update:visible", val);
      },
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    handleClose() {
      this.isShow = false;
    },
    fetchData() {
      getInspectionRecord(this.id).then((res) => {
        if (isResOK(res)) {
          this.detailForm = res.data;
          this.detailForm.appearanceDescriptionFileList.forEach((item) => {
            this.appearanceList.push(baseURL + item.storageBlob.newFilename);
          });
          this.detailForm.columnInstallationFileList.forEach((item) => {
            this.columnList.push(baseURL + item.storageBlob.newFilename);
          });
          this.detailForm.socketInstallationFileList.forEach((item) => {
            this.socketlist.push(baseURL + item.storageBlob.newFilename);
          });

          this.detailForm.pipelineRoutingFileList.forEach((item) => {
            this.pipelineRoutinglist.push(
              baseURL + item.storageBlob.newFilename
            );
          });

          this.detailForm.abnormalCoinChargingFileList.forEach((item) => {
            this.abnormalCoinCharginglist.push(
              baseURL + item.storageBlob.newFilename
            );
          });

          this.detailForm.buttonNormalFileList.forEach((item) => {
            this.buttonNormallist.push(baseURL + item.storageBlob.newFilename);
          });

          this.detailForm.otherProblemFileList.forEach((item) => {
            this.otherProblemlist.push(baseURL + item.storageBlob?.newFilename);
          });
        }
      });
    },
  },
};
</script>
<style scoped>
.el-descriptions {
  margin-left: 20px;
}
</style>
