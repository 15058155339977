<template>
  <div>
    <el-main>
      <el-form
          :model="searchForm"
          ref="queryForm"
          :inline="true"
          label-width="85px"
          label-position="left"
      >
        <el-row>
          <el-col :span="6">
            <el-form-item label="设备编号">
              <el-input
                  v-model="searchForm.deviceNo"
                  placeholder="请输入设备编号"
                  clearable
                  style="width: 240px"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="设备名称">
              <el-input
                  v-model="searchForm.deviceName"
                  placeholder="请输入设备名称"
                  clearable
                  style="width: 240px"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="设备类型">
              <el-select
                  v-model="searchForm.deviceType"
                  placeholder="请选择设备类型"
                  clearable
                  style="width: 240px"
              >
                <el-option
                    v-for="dict in dict.type.device_type"
                    :key="dict.value"
                    :label="dict.label"
                    :value="dict.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="项目名称">
              <el-input
                  v-model="searchForm.projectName"
                  placeholder="请输入项目名称"
                  clearable
                  style="width: 240px"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="项目类型">
              <el-select
                  v-model="searchForm.projectTypeId"
                  placeholder="请选择项目类型"
                  clearable
                  style="width: 240px"
              >
                <el-option
                    v-for="item in projectTypeId"
                    :label="item.name"
                    :value="item.id"
                    :key="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="选择结果">
              <el-select
                  v-model="searchForm.result"
                  placeholder="请选择结果"
                  clearable
                  style="width: 240px"
              >
                <el-option
                    v-for="dict in dict.type.inspection_record_result"
                    :key="dict.value"
                    :label="dict.label"
                    :value="dict.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="巡检时间">
              <el-date-picker
                  v-model="searchForm.queryTime"
                  type="date"
                  placeholder="选择巡检时间"
                  @change="formattedDate"
                  clearable
                  style="width: 240px"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-button type="primary" @click="search(this.searchForm)">搜索</el-button>
            <el-button type="warning" @click="refresh">刷新</el-button>
          </el-col>
        </el-row>
      </el-form>
      <el-table v-loading="loading" :data="listForm">
        <el-table-column width="100">
          <template #default="scope">
            <div
                style="
                display: flex;
                align-items: center;
                justify-content: center;
              "
            >
              <el-image
                  style="width: 49px; height: 49px"
                  :src="backImgUrl"
                  fit="contain"
              />
              <el-image
                  style="width: 23px; height: 22px; position: absolute"
                  :src="imgUrl"
                  fit="contain"
              />
            </div>
          </template>
        </el-table-column>
        <el-table-column>
          <template #default="scope">
            <el-descriptions :column="4" :title="scope.row.inspectionNo">
              <template #extra>
                <el-tooltip
                    effect="light"
                    content="操作历史"
                    placement="bottom"
                >
                  <el-icon
                      :size="20"
                      color="#1396db"
                      style="margin-right: 10px"
                      @click="history(scope.row.id)"
                  >
                    <Clock/>
                  </el-icon>
                </el-tooltip>
                <el-tooltip effect="light" content="详情" placement="bottom">
                  <el-icon
                      :size="20"
                      color="#1396db"
                      style="margin-right: 10px"
                      @click="detail(scope.row.id)"
                  >
                    <Tickets
                    />
                  </el-icon>
                </el-tooltip>
                <el-tooltip
                    effect="light"
                    content="删除记录"
                    placement="bottom"
                >
                  <el-icon
                      :size="20"
                      color="#ff0000"
                      style="margin-right: 10px"
                      @click="deleteInspection(scope.row.id)"
                  >
                    <Delete/>
                  </el-icon>
                </el-tooltip>
              </template>

              <el-descriptions-item label="设备编号: " width="100">{{
                  scope.row?.deviceNo
                }}
              </el-descriptions-item>
              <el-descriptions-item label="项目名称: " width="100">{{
                  scope.row.deviceLocation?.project.name
                }}
              </el-descriptions-item>
              <el-descriptions-item label="巡检人:   " width="100">{{
                  scope.row.userName
                }}
              </el-descriptions-item>
              <el-descriptions-item label="巡检结果: " width="100">{{
                  selectDictLabel(
                      dict.type.inspection_record_result,
                      scope.row.result
                  )
                }}
              </el-descriptions-item>

              <el-descriptions-item label="设备名称: ">
                {{ scope.row.deviceLocation?.deviceName }}
              </el-descriptions-item>
              <el-descriptions-item label="项目类型: ">{{
                  scope.row.deviceLocation?.project.projectType.name
                }}
              </el-descriptions-item>
              <el-descriptions-item label="巡检时间: ">{{
                  scope.row.inspectionTime
                }}
              </el-descriptions-item>
              <el-descriptions-item label="创建时间：">{{
                  scope.row.createTime
                }}
              </el-descriptions-item>
            </el-descriptions>
          </template>
        </el-table-column>
      </el-table>
      <pagination
          v-show="total > 0"
          :total="total"
          v-model:page="query.pageNum"
          v-model:limit="query.pageSize"
          @pagination="fetchData"
      />
      <Detail
          v-if="isShowDetail"
          v-model:visible="isShowDetail"
          :id="detailId"
      ></Detail>
      <operation-history-list-modal
          v-if="isShowHistory"
          :reference-id="historyId"
          reference-type="InspectionRecord"
          v-model:visible="isShowHistory"
      />
    </el-main>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import Detail from "./Detail.vue";
import {selectDictLabel} from "@/utils/ruoyi";
import {isResOK} from "@/api/response";
import {
  listInspectionRecord,
  delInspectionRecord,
} from "@/api/inspection_record.js";
import {ElMessage} from "element-plus";
import {listProjectType} from "@/api/project_type.js";
import OperationHistoryListModal from "@/view/operation_history/Modal.vue";
import store from "@/store";
import {getUrlParams} from "@/api/userList";
import {getCurrentUser} from "@/api/user";

export default {
  name: "",
  components: {
    Pagination,
    Detail,
    OperationHistoryListModal,
  },
  dicts: ["device_type", "inspection_record_result"],
  data() {
    return {
      total: 0,
      query: {
        pageNum: 1,
        pageSize: 10,
      },

      searchForm: {
        deviceNo: null, //设备编号
        deviceName: null, //设备名称
        deviceType: null, //设备类型
        name: null, //项目名称
        projectTypeId: null, //项目类型
        queryTime: null, //巡检时间
      },
      projectTypeId: [],
      listForm: [],
      isShowDetail: false, //展示详情框
      detailId: 0, //详情展示的数据对应id
      isShowHistory: false, //显示操作历史
      historyId: 0, //操作历史数据对应id
      backImgUrl: require(`/src/assets/inspection_back.svg`),
      imgUrl: require(`/src/assets/inspection.png`),
    };
  },
  created() {
    this.fetchData();
    this.projectTypeList()
    this.fetchCurrentUser()
  },
  methods: {
    fetchCurrentUser() {
      store.dispatch('user_info/cleanUser')
      let data = {}
      const params = getUrlParams(window.location.href)
      if (params[`accessToken`]) {
        sessionStorage.setItem('token', params[`accessToken`])
        data[`auth-token`] = params[`accessToken`]
        getCurrentUser(data).then(res => {
          if (isResOK(res)) {
            store.dispatch('user_info/setUser', res.data)
          }
        })
      }
    },

    fetchData() {
      listInspectionRecord(this.query).then((res) => {
        if (isResOK(res)) {
          this.total = res.total;
          this.listForm = res.rows;
        }
      });
    },
    refresh() {
      this.searchForm = {};
      this.search(this.searchForm);
    },
    projectTypeList() {
      listProjectType().then((res) => {
        if (isResOK(res)) {
          this.projectTypeId = res.rows;
        }
      });
    },
    search(searchForm) {
      this.query = Object.assign({}, searchForm, {pageNum: this.query.pageNum});
      this.fetchData();
    },
    formattedDate() {
      const date = new Date(this.searchForm.queryTime);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      const formattedDate = `${year}-${month}-${day}`;
      this.searchForm.queryTime = formattedDate;
    },
    deleteInspection(id) {
      this.$messageBox
          .confirm("确定进行删除吗?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
          .then(() => {

            delInspectionRecord(id, { operatingMode: 'web' }).then((res) => {
              if (isResOK(res)) {
                this.$message({
                  message: "删除成功",
                  type: "success",
                });
              }
              this.fetchData();
            });
          });
    },
    detail(id) {
      this.detailId = id;
      this.isShowDetail = true;
    },
    history(id) {
      this.historyId = id;
      this.isShowHistory = true;
    },
  },
};
</script>

<style scoped>
.el-form-item {
  margin-right: 15px;
}
</style>
