import request from '@/utils/request'

// 查询操作历史列表
export function listOperationHistory(query) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/operation_history/list',
        method: 'get',
        params: query
    })
}

// 查询操作历史详细
export function getOperationHistory(id) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/operation_history/' + id,
        method: 'get'
    })
}

// 新增操作历史
export function addOperationHistory(data) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/operation_history',
        method: 'post',
        data: data
    })
}

// 修改操作历史
export function updateOperationHistory(data) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/operation_history',
        method: 'put',
        data: data
    })
}

// 删除操作历史
export function delOperationHistory(id) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/operation_history/' + id,
        method: 'delete'
    })
}
