<template>
  <div :style="{ height: this.height }" class="scroll-box">
    <el-timeline v-loading="loading">
      <el-timeline-item v-if="data.length > 0"
          v-for="(activity, index) in data"
          :key="index"
          :type="activity.type"
          color="#0bbd87"
          :timestamp="activity.createTime + '&nbsp&nbsp&nbsp&nbsp'+activity.operationTypeName +'(' + activity.operatorName + ')'"
          placement="top"
      >
        {{ activity.content }}
      </el-timeline-item>
      <el-timeline v-else>
        暂无数据
      </el-timeline>
    </el-timeline>
  </div>
</template>

<script>
import {listOperationHistory} from "@/api/operation_history";
import {isResOK} from "@/api/response";

export default {
  name: "OperationHistoryList",
  props: {
    referenceType: {
      type: String,
      required: true
    },
    referenceId: {
      type: Number,
      required: true
    },
    height: {
      type: String,
      default: '400px'
    }
  },
  data() {
    return {
      loading: true,
      data: []
    }
  },
  created() {
    this.fetchData()
  },
  filters: {
    operatorNameFilter(value) {
      if (value.length > 10) {
        return value.substring(0, 10) + '...'
      }
      return value
    }
  },
  methods: {
    fetchData() {
      this.loading = true
      listOperationHistory({ referenceTypeName: this.referenceType, referenceId: this.referenceId }).then((res) => {
        if (isResOK(res)) {
          this.data = res.rows
        }
        this.loading = false
      })
    }
  }
}
</script>

<style scoped lang="less">
.scroll-box {
  overflow-y: auto;
  padding-top: 5px;
  padding-right: 10px;
}
</style>
