import request from '@/utils/request'

// 查询巡检记录列表
export function listInspectionRecord(query) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/inspection_record/list',
        method: 'get',
        params: query
    })
}

// 查询巡检记录详细
export function getInspectionRecord(id) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/inspection_record/' + id,
        method: 'get'
    })
}

// 新增巡检记录
export function addInspectionRecord(data) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/inspection_record',
        method: 'post',
        data: data
    })
}

// 修改巡检记录
export function updateInspectionRecord(data) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/inspection_record',
        method: 'put',
        data: data
    })
}

// 删除巡检记录
export function delInspectionRecord(id, data) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/inspection_record/' + id,
        method: 'delete',
        data: data
    })
}
