<template>
  <el-dialog
      v-model="isShow"
      title="操作历史"
      width="900px"
      :before-close="handleClose"
  >
    <operation-history-list :reference-id="referenceId" :reference-type="referenceType" :height="height"></operation-history-list>
  </el-dialog>
</template>

<script>
import OperationHistoryList from "@/view/operation_history/index.vue";

export default {
  name: "OperationHistoryListModal",
  components: {OperationHistoryList},
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    referenceType: {
      type: String,
      required: true
    },
    referenceId: {
      type: Number,
      required: true
    },
    height: {
      type: String,
      default: '400px'
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  methods: {
    handleClose() {
      this.isShow = false
    }
  }
}
</script>

<style scoped>

</style>
